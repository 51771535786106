<template>
    <div>
        <!-- <upload-img v-model="img"></upload-img> -->
        <a-spin :spinning="loading">
            <div class="bg-w pd20" style="min-height: 800px;min-width: 1000px;">
                <div style="width: 800px;">
                    <div class="ft20 ftw500 cl-main">站点设置</div>
                    <a-alert class="mt20" message="平台信息的设置,主要更改LOGO和版权信息" type="info" />
                    <div v-if="datas!=null" class="mt20">
                        <a-form-model ref="ruleForm" :model="datas" :label-col="{ span: 4}" :wrapper-col="{ span: 20}">

                            <a-form-model-item label="总控名称">
                                <a-input v-model="datas.site_name" style="width: 300px;" />
                            </a-form-model-item>
                            <a-form-model-item label="电话号码">
                                <a-input v-model="datas.mobile" style="width: 300px;" />
                            </a-form-model-item>
                            <a-form-model-item label="站点域名">
                                <a-input v-model="datas.domain_name" style="width: 300px;" />
                            </a-form-model-item>
                            <a-form-model-item label="子控名称">
                                <a-input v-model="datas.zikong_name" style="width: 300px;" />
                            </a-form-model-item>
                            <a-form-model-item label="联系我们电话号码">
                                <a-input v-model="datas.contact_Telephone" style="width: 300px;" />
                            </a-form-model-item>

                            <a-form-model-item label="圆形LOGO" help="商铺后台使用">
                                <upload-img v-model="datas.logo_url"></upload-img>
                            </a-form-model-item>
                            <a-form-model-item label="用户后台登录" help="请使用白色透明的">
                                <upload-img v-model="datas.user_backend_url"></upload-img>
                            </a-form-model-item>
                            <a-form-model-item label="用户后台应用管理" help="请使用白色透明的">
                                <upload-img v-model="datas.user_backend_applications_url"></upload-img>
                            </a-form-model-item>

                            <a-form-model-item label="总后台登录" help="请使用白色透明的">
                                <upload-img v-model="datas.general_backend_url"></upload-img>
                            </a-form-model-item>
                            <a-form-model-item label="总后台应用管理" help="请使用白色透明的">
                                <upload-img v-model="datas.application_management_url"></upload-img>
                            </a-form-model-item>
                            <a-form-model-item label="联系我们二维码" help="请使用白色透明的">
                                <upload-img v-model="datas.contact_QRcode"></upload-img>
                            </a-form-model-item>

                            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                                <a-button type="primary" @click="saveData">
                                    保存设置
                                </a-button>
                            </a-form-model-item>
                        </a-form-model>
                    </div>

                </div>
            </div>
        </a-spin>
    </div>
</template>

<script>
import uploadImg from '../../components/upload/upload.vue';
export default {
    components: {
        uploadImg
    },
    data() {
        return {
            img: '',
            loading: false,
            datas: null,
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true;
            this.$http.api('api/Install/InstallInfo', {
                type: 'Site'
            }).then(res => {
                this.loading = false;
                let datas = res.data.json_data

                this.datas = {
                    site_name: datas.site_name.value,
                    mobile: datas.mobile.value,
                    domain_name: datas.domain_name.value,
                    zikong_name: datas.zikong_name?datas.zikong_name.value:'',
                    logo_url: datas.logo_url.value,
                    user_backend_url: datas.user_backend_url.value,
                    user_backend_applications_url: datas.user_backend_applications_url.value,
                    general_backend_url: datas.general_backend_url.value,
                    application_management_url: datas.application_management_url.value,
                    contact_Telephone: datas.contact_Telephone.value,
                    contact_QRcode: datas.contact_QRcode.value,
                };
            }).catch(res => {
                this.loading = false;
            })
        },
        saveData() {
            this.loading = true;
            this.$http.api('api/Install/SiteEdit', this.datas).then(res => {
                this.loading = false;
                this.$message.success('保存成功');
            }).catch(res => {
                this.loading = false;
            })
        }
    }
}
</script>

<style>
</style>